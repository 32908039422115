var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Barang",
      "label-for": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Ex: Kaos Polos"
          },
          model: {
            value: _vm.form.nama,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nama", $$v);
            },
            expression: "form.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Dasar",
      "label-for": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "harga_dasar",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          attrs: {
            "prepend": "Rp."
          }
        }, [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Harga Dasar"
          },
          on: {
            "keyup": _vm.doFormatRupiah
          },
          model: {
            value: _vm.form.harga_dasar,
            callback: function ($$v) {
              _vm.$set(_vm.form, "harga_dasar", $$v);
            },
            expression: "form.harga_dasar"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Barang"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jenis",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.options,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.jenis,
            callback: function ($$v) {
              _vm.$set(_vm.form, "jenis", $$v);
            },
            expression: "form.jenis"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "10",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kategori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.kategoriOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.id_kategori,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_kategori", $$v);
            },
            expression: "form.id_kategori"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "10",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "satuan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.satuanOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.id_satuan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_satuan", $$v);
            },
            expression: "form.id_satuan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume Aset"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "volume",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: "
          },
          model: {
            value: _vm.form.volume,
            callback: function ($$v) {
              _vm.$set(_vm.form, "volume", $$v);
            },
            expression: "form.volume"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Aset"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: ",
            "type": "date"
          },
          model: {
            value: _vm.form.tanggal,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }