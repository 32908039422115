<template>
    <main>
        <b-card-actions :no-actions="true">
            <validation-observer ref="form">
                <b-row class="mb-2">
                <b-col md="5" sm="12">
                    <b-form-group label="Nama Barang" label-for="">
                    <validation-provider
                        #default="{ errors }"
                        name="nama"
                        :rules="{ required: true }"
                    >
                        <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nama"
                        placeholder="Ex: Kaos Polos"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group label="Harga Dasar" label-for="">
                    <validation-provider
                        #default="{ errors }"
                        name="harga_dasar"
                        :rules="{ required: true }"
                    >
                    <b-input-group
                            prepend="Rp."
                            class="input-group-merge"
                            >
                        <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.harga_dasar"
                        @keyup="doFormatRupiah"
                        placeholder="Harga Dasar"
                        />
                    </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="6">
                    <b-form-group label="Jenis Barang">
                    <validation-provider
                        #default="{ errors }"
                        name="jenis"
                        :rules="{ required: true }"
                    >
                        <b-form-select
                        v-model="form.jenis"
                        :options="options"
                        :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col sm="10" md="6">
                        <b-form-group label="Kategori">
                        <validation-provider
                            #default="{ errors }"
                            name="kategori"
                            :rules="{ required: true }"
                        >
                            <v-select
                            v-model="form.id_kategori"
                            :options="kategoriOptions"
                            :reduce="(option) => option.value"
                            label="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col sm="10" md="6">
                        <b-form-group label="Satuan">
                        <validation-provider
                            #default="{ errors }"
                            name="satuan"
                            :rules="{ required: true }"
                        >
                            <v-select
                            v-model="form.id_satuan"
                            :options="satuanOptions"
                            :reduce="(option) => option.value"
                            label="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="6">
                      <b-form-group label="Volume Aset">
                        <validation-provider
                            #default="{ errors }"
                            name="volume"
                            :rules="{ required: true }"
                        >
                            <b-form-input
                                placeholder="Ex: "
                                v-model="form.volume"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                      <b-form-group label="Tanggal Aset">
                        <validation-provider
                            #default="{ errors }"
                            name="tanggal"
                            :rules="{ required: true }"
                        >
                            <b-form-input
                                placeholder="Ex: "
                                v-model="form.tanggal"
                                type="date"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                  </b-col>
                </b-row>
                <div class="d-flex justify-content-end">
                <b-button @click.prevent="submit" variant="primary">
                    {{ label }}
                </b-button>
                </div>
            </validation-observer>
            </b-card-actions>
    </main>
  </template>
  <script>
  import Ripple from "vue-ripple-directive";
  import Cleave from "vue-cleave-component";
  import "cleave.js/dist/addons/cleave-phone.us";
  import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import {
    BInputGroup,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BPagination,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BCard,
    BTable,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormFile,
    BFormTextarea,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "This field is required",
  });
  
  export default {
    components: {
      BInputGroup,
      Cleave,
      BTabs,
      BTab,
      BTable,
      BCard,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      BCardActions,
      BContainer,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BFormFile,
      BFormTextarea,
      VBTooltip,
      BDropdown,
      BDropdownItem,
      BAvatar,
      BBadge,
      BModal,
      BPagination,
      BInputGroupPrepend,
      BInputGroupAppend,
      BFormCheckbox,
      BForm,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data: () => ({
      label: "Simpan",
      konsumenTypes: [],
      selected: null,
      priceList: [],
      options2: {
          number: {
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
          },
        },
      file: null,
      form: {
        nama: null,
        jenis: null,
        volume: null,
        tanggal: null,
        harga_dasar: 0,
        id_kategori: null,
        id_satuan: null
      },
      canManagePrice: false,
      options: [
        // jenis options
        {
          value: 1,
          text: "TETAP",
        },
        {
          value: 2,
          text: "BHP",
        },
      ],
      selectedSatuan: null,
      selectedKategori: null,
      kategoriOptions: [],
      satuanOptions: [],
      showModalEdit: false,
      showModal: false,
      id: null,
      form2: {
        nilai: 0,
        id_satuan: null,
      },
      id_barang: [],
      id_satuan: [],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields2: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        // { key: "nilai", label: "Nama Gudang", sortable: true },
        { key: "satuan.satuan", label: "dari satuan" },
        { key: "nilai", label: "Jumlah" },
        { key: "id_barang", label: "Satuan Terkecil" },
        // { key: "", label: "ke satuan" },
        // { key: "barang.nama", label: "barang", sortable: true },
        // { key: "alamat", label: "alamat" },
        // { key: "keterangan", label: "Keterangan" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    }),
  
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    methods: {
        doFormatRupiah() {
            this.form.harga_dasar = this.formatRupiah(this.form.harga_dasar)
        },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      submit() {
        this.$refs.form.validate().then(async (success) => {
          if (success) {
            let payload = this.form;
            payload.harga_dasar = this.unFormatRupiah(payload.harga_dasar)
            try {
                this.label = "Loading...";
                await this.$store.dispatch("asset/save", [payload])
                this.label = "Simpan";
                const message = this.$route.params.id
                  ? "Barang Aset berhasil diperbaharui"
                  : "Barang Aset berhasli ditambahkan";
                this.displaySuccess({
                  message
                });

                setTimeout(() => {
                    this.$router.push('/asset')
                }, 1000)
            }
            catch(e) {
                this.label = "Simpan";
                this.displayError(e);
                return false;
            };
          }
        });
      },
      getBarangName(data) {
        let find = this.id_barang.find((item) => item.id == data.id_barang);
        return find ? find.satuan.satuan : "-";
      },
      async getKategori() {
        let kategori = await this.$store.dispatch("kategori-asset/getData");
        kategori = JSON.parse(JSON.stringify(kategori));
        let newKategori = [];
        kategori.map((item) => {
          newKategori.push({
            text: item.kategori,
            value: item.id,
          });
        });
  
        this.kategoriOptions = newKategori;
      },
      resetForm() {
        this.form2 = {
          id_barang: this.$route.params.id,
          nilai: 0,
          id_satuan: 0,
        };
      },
      
      edit(item) {
        let { satuan, barang } = item;
        this.id = item.id;
        this.form2 = item;
        this.form2.nilai = item.nilai;
        if (satuan) this.form2.id_satuan = satuan.id;
        if (barang) this.form2.id_barang = barang.id;
        this.showModal = true;
      },
      remove(item) {
        this.$swal({
          title: "Anda yakin?",
          text: `Data konversi harga ini akan dihapus`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            item.fungsi = 1; // soft delete
            this.$store
              .dispatch("konversi/save", [item])
              .then(() => {
                this.getDataKonversi();
                this.displaySuccess({
                  message: "Konversi harga berhasil dihapus!",
                });
              })
              .catch((e) => {
                //   this.pesanGagalHapus();
                this.displayError(e);
                return false;
              });
          }
        });
      },
      async getSatuan() {
        const satuan = await this.$store.dispatch('satuan/getData')
        this.satuanOptions = satuan.map(st => ({ value: st.id, text: st.satuan }))
      }
    },
    async created() {
      
      this.getSatuan()
      this.getKategori();
  
      if (this.$route.params.id) {
        let barang = this.$store.getters["asset/searchBarang"](this.$route.params.id);
        if (!barang) {
          barang = await this.$store.dispatch("asset/getBarangById", this.$route.params.id);
        }
        this.form = {
          id: barang.id,
          nama: barang.nama,
          jenis: barang.jenis,
          id_kategori: barang.kategori ? barang.kategori.id : null,
          harga_dasar: this.formatRupiah(barang.harga_dasar),
          kode: barang.kode,
          volume: barang.volume,
          tanggal: barang.tanggal,
          id_satuan: barang.satuan ? barang.satuan.id : null
        };
      }
    },
  };
  </script>
  